<template>
  <div>
    <b-button variant="primary" @click="launchWhatsAppSignup"
      >Login com Facebook</b-button
    >
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  props: {
    block: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      sessionInfo: null,
      popup: null
    };
  },

  created() {
    this.sessionInfoPromise = new Promise((resolve) => {
      const sessionInfoListener = (event) => {
        if (event.origin !== "https://www.facebook.com") return;
        try {
          const data = JSON.parse(event.data);
          if (data.type === "WA_EMBEDDED_SIGNUP") {
            // if user finishes the Embedded Signup flow
            if (data.event === "FINISH") {
              //const { phone_number_id, waba_id } = data.data;
              this.sessionInfo = data.data; // Armazena as informações da sessão
              resolve();
            }
            // if user cancels the Embedded Signup flow
            else {
              const { current_step } = data.data;
            }
          }
        } catch {
          // Don’t parse info that’s not a JSON
          //console.log("Non JSON Response", event.data);
        }
      };

      window.addEventListener("message", sessionInfoListener);
    });
  },

  methods: {
    launchWhatsAppSignup() {
      // Launch Facebook login
      window.FB.login(
        (response) => {
          this.sessionInfoPromise.then(() => {
            if (response.authResponse) {
              ApiService.post("whatsapp/access_token", {
                code: response.authResponse.code,
                waba_id: this.sessionInfo.waba_id
              }).then((response) => {
                this.$emit("login-success", this.sessionInfo);
              });
            } else {
              console.log(
                "Usuário cancelou o login ou não autorizou completamente."
              );
            }
          });
        },
        {
          config_id: "7522076867879900", // configuration ID obtained in the previous step goes here
          response_type: "code", // must be set to 'code' for System User access token
          override_default_response_type: true,
          extras: {
            setup: {},
            sessionInfoVersion: 2 //  Receive Session Logging Info
          }
        }
      );
    }
  }
};
</script>
