<template>
  <i class="fa fa-question-circle" v-b-tooltip.hover :title="message"></i>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
i {
  font-size: 1rem;
}
</style>
