<template>
  <div id="app">
    <div
      class="container"
      style="margin-bottom: 20px
      background-color: #cfcbcb"
    >
      <div class="row">
        <div class="col-md-15">
          <h5 class="card-label">Previsão de Faturamento Mensal por Setor</h5>
          <div class="form-row">
            <div class="col-md-3" style="display: flex; align-items: center">
              <h5
                for="filtro_ano"
                style="white-space: nowrap; margin-right: 60px"
              >
                Selecione um setor:
              </h5>
              <b-select
                id="filtroSetor"
                @change="montarTabela(selectedSetor)"
                v-model="selectedSetor"
                style="width: 350px"
              >
                <option
                  v-for="setor in setores"
                  :value="setor.ate_setor_codigo"
                  :key="setor.ate_setor_codigo"
                >
                  {{ setor.nome }}
                </option>
              </b-select>
            </div>
          </div>
          <form class="mb-1" id="previsoes">
            <div class="container" id="tabela-container"></div>
            <div
              class="text-right"
              style="padding-right: 20px"
              id="button-container"
            ></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import axios from "axios";
import Vue from "vue";
import "vuetify/dist/vuetify.css";
import Vuetify from "vuetify";
import Swal from "sweetalert2";

Vue.use(Vuetify);

export default {
  components: {},
  data() {
    return {
      urlBase: "",
      role: "",
      token: "",
      setores: [],
      selectedSetor: null
    };
  },
  mounted() {
    this.inicializar();
  },
  computed: {},
  methods: {
    async inicializar() {
      await this.autenticarBI();
      this.receberSetores();
    },
    async autenticarBI() {
      await ApiService.get("nps").then((response) => {
        this.urlBase = response.data.data.url;
        this.role = response.data.data.role;
        this.token = response.data.data.token;
      });
    },
    async receberSetores() {
      let resposta = await axios.get(`${this.urlBase}setores`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });
      this.setores = resposta.data;
    },

    async receberPrevisoes(setor) {
      let resposta = await axios.get(`${this.urlBase}previsoes`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        },
        params: {
          setor_codigo: setor
        }
      });

      return resposta.data;
    },
    salvarPrevisoes() {
      let setor = document.getElementById("filtroSetor").value;
      let tabela = document.getElementById("tabelaFormulario");

      let valores_ano = {};

      for (let i = 1; i < tabela.rows.length; i++) {
        let linha = tabela.rows[i];
        let ano = linha.cells[0].textContent;

        let valores = {};

        let inputs = linha.getElementsByTagName("input");

        for (let j = 0; j < inputs.length; j++) {
          let input = inputs[j];

          let valor_mes = input.value;

          valores[j + 1] = valor_mes;
        }

        valores_ano[ano] = valores;
      }

      let lista_previsoes = [];

      const entries = Object.entries(valores_ano);

      for (let [ano_previsao, previsoes] of entries) {
        const e = Object.entries(previsoes);

        for (let [mes_previsao, valor_previsao] of e) {
          if (valor_previsao !== "") {
            lista_previsoes.push({
              setor_codigo: setor,
              mes: mes_previsao,
              ano: ano_previsao,
              valor: valor_previsao
            });
          }
        }
      }

      axios
        .post(
          `${this.urlBase}previsoes`,
          { previsoes: lista_previsoes },
          {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          }
        )
        .then((response) => {
          Swal.fire({
            title: "Sucesso!",
            text: "Previsões salvas com sucesso!",
            icon: "success",
            confirmButtonText: "Ok"
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro!",
            text: "Houve um problema ao salvar as previsões. Tente novamente.",
            icon: "error",
            confirmButtonText: "Ok"
          });
        });
    },
    async montarTabela(setor_codigo) {
      let tabelaContainer = document.getElementById("tabela-container");
      let botaoContainer = document.getElementById("button-container");
      tabelaContainer.innerHTML = "";
      botaoContainer.innerHTML = "";

      let table = document.createElement("table");
      table.setAttribute("class", "v-table");
      table.setAttribute("id", "tabelaFormulario");

      let thead = document.createElement("thead");
      table.appendChild(thead);

      let linhaCabecalho = document.createElement("tr");
      thead.appendChild(linhaCabecalho);

      let cabecalho = [
        "Ano",
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
      ];

      cabecalho.forEach(function (textoCabecalho) {
        let celulaCabecalho = document.createElement("th");
        celulaCabecalho.setAttribute("style", "text-align: center");
        celulaCabecalho.textContent = textoCabecalho;
        linhaCabecalho.appendChild(celulaCabecalho);
      });

      let tbody = document.createElement("tbody");
      table.appendChild(tbody);

      const previsoes = await this.receberPrevisoes(setor_codigo);

      let anoAtual = new Date().getFullYear();

      for (let ano = anoAtual; ano < anoAtual + 4; ano++) {
        //Exibe, por padrão, o ano atual e os quatro seguintes
        let tr = document.createElement("tr");

        let td = document.createElement("td");
        td.textContent = ano;
        td.setAttribute("style", "vertical-align: middle");
        tr.appendChild(td);

        for (let mes = 1; mes <= 12; mes++) {
          //Cria uma célula da tabela para cada mês de cada ano
          let td = document.createElement("td");

          let input = document.createElement("input");
          input.setAttribute("class", "form-control");
          input.value =
            previsoes && previsoes.hasOwnProperty(ano) && mes in previsoes[ano]
              ? previsoes[ano][mes][0].valor_previsao
              : "";

          input.addEventListener(
            "input",
            (function (input) {
              return function () {
                let valor = input.value.replace(/[^0-9]/g, "");
                let valorFormatado = parseInt(valor).toLocaleString();
                input.value = valorFormatado;
              };
            })(input)
          ); // Usando uma função de fechamento (closure) para capturar o valor atual do input

          td.appendChild(input);
          tr.appendChild(td);
        }

        tbody.appendChild(tr);
      }

      tabelaContainer.appendChild(table);

      const botaoSalvar = document.createElement("a");
      botaoSalvar.textContent = "Salvar";
      botaoSalvar.classList.add("btn", "btn-primary");
      botaoSalvar.onclick = this.salvarPrevisoes;

      botaoContainer.appendChild(botaoSalvar);
    }
  }
};
</script>

<style>
.form-row {
  align-items: flex-end;
}

.form-row .col-md-2 {
  margin-top: 32px;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  text-align: center;
  padding-right: 50px;
}

.linha-destaque {
  border: 2px solid rgba(0, 0, 0, 0.5);
}

#tabelaFormulario th:first-child {
  width: 55px;
}

#tabelaFormulario th:not(:first-child) {
  width: 85px;
}
</style>
