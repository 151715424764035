<template>
  <div id="app">
    <b-modal
      id="mdlCaixaEntrada"
      ref="mdlCaixaEntrada"
      title="Escolher conta"
      ok-title="Salvar"
      ok-only
      @ok="salvarNumerosWhatsapp"
      size="lg"
    >
      <b-form
        v-if="selectedNumber"
        id="frmNumerosWhatsapp"
        ref="frmNumerosWhatsapp"
      >
        <b-row class="mb-1">
          <b-col cols="3">
            <b-form-input
              v-model="selectedNumber.phone_number"
              disabled
            ></b-form-input>
          </b-col>
          <b-col cols="5">
            <b-form-input
              v-model="selectedNumber.inbox_name"
              placeholder="Nome da caixa de entrada"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-select
              v-model="selectedNumber.conta_id"
              :options="accountOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <div>
      <div style="text-align: right">
        <b-button @click="loadNumbersInformation()">
          <i class="fa-solid fa-rotate-right" style="font-size: small"></i>
        </b-button>
      </div>
      <div v-for="(account, i) in accounts" :key="i">
        <div style="align-items: center; justify-content: flex-end">
          <div>
            <h6
              for="filtro_ano"
              style="white-space: nowrap; margin-right: 10px"
            >
              {{ account.waba_name }}
            </h6>
          </div>
        </div>
        <div class="table-numbers" style="margin-bottom: 20px">
          <table class="table table-hover" v-if="numbers.length > 0">
            <thead>
              <tr class="text-center">
                <th style="width: 18%">
                  Número de telefone
                  <Tooltip
                    message="Número de telefone cadastrado na conta do WhatsApp Business."
                  />
                </th>
                <th style="width: 20%">
                  Nome
                  <Tooltip message="Nome verificado da linha." />
                </th>
                <th style="width: 20%">
                  Conta
                  <Tooltip message="Conta a qual esse número está associado." />
                </th>
                <th style="width: 10%">
                  Status
                  <Tooltip
                    message="Indica se o número está ou não registrado. É possível registrá-lo no menu de Ações."
                  />
                </th>
                <th style="width: 18%">
                  Caixa de entrada
                  <Tooltip
                    message="Indica se já existe uma caixa de entrada no Atende. É possível criá-la no menu de Ações."
                  />
                </th>
                <th style="width: 10%">
                  Ações
                  <Tooltip
                    message="Ações disponíveis para o número de telefone."
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="numero in account.numbers"
                :key="numero.phone_number"
                class="text-center"
              >
                <td>
                  {{ numero.phone_number }}
                </td>
                <td>{{ numero.name }}</td>
                <td>
                  <div
                    v-if="
                      accountOptions.find(
                        (option) => option.value === numero.conta_id
                      )
                    "
                  >
                    {{
                      accountOptions.find(
                        (option) => option.value === numero.conta_id
                      ).text
                    }}
                    <br />
                    {{
                      accountOptions.find(
                        (option) => option.value === numero.conta_id
                      ).name
                    }}
                  </div>
                </td>
                <td>
                  <div class="icones-container">
                    <span
                      class="label status-on label-inline mr-2"
                      v-if="numero.registered"
                      >Registrado</span
                    >
                    <span
                      v-else
                      class="label status-off label-inline mr-2"
                      style="width: 100px"
                      >Não registrado</span
                    >
                  </div>
                </td>
                <td>
                  <div class="icones-container">
                    <span
                      class="label status-on label-inline mr-2"
                      v-if="numero.inbox"
                      >Ativa</span
                    >
                    <span class="label status-off label-inline mr-2" v-else
                      >Inativa</span
                    >
                  </div>
                </td>
                <td>
                  <div class="icones-container">
                    <button
                      @click="register(numero, account.waba_id)"
                      class="btn btn-icon btn-light-primary btn-sm"
                      title="Registrar número"
                      style="margin-right: 10px"
                      :disabled="numero.registered"
                      :style="{
                        cursor: numero.registered ? 'not-allowed' : 'pointer'
                      }"
                    >
                      <i
                        class="fa-solid fa-clipboard-check"
                        style="font-size: small"
                      ></i>
                    </button>

                    <button
                      class="btn btn-icon btn-light-primary btn-sm"
                      title="Criar caixa de entrada"
                      @click="createInbox(numero, account.waba_id)"
                      :disabled="numero.inbox && numero.registered"
                      :style="{
                        cursor: numero.inbox ? 'not-allowed' : 'pointer'
                      }"
                    >
                      <i class="flaticon2-talk" style="font-size: small"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";
import "vuetify/dist/vuetify.css";
import Vuetify from "vuetify";
import Swal from "sweetalert2";
import Tooltip from "./dashboard/Tooltip.vue";

Vue.use(Vuetify);

export default {
  components: { Tooltip },
  data() {
    return {
      accountOptions: [],
      numbers: [],
      wabaOptions: [],
      selectedNumber: null,
      selectedWaba: null,
      accounts: []
    };
  },
  mounted() {
    this.inicializar();
  },
  computed: {},
  methods: {
    inicializar() {
      this.getWabas();
      this.getAccounts();
    },
    createInbox(numero, waba_id) {
      this.selectedNumber = numero;
      this.selectedWaba = waba_id;
      this.$bvModal.show("mdlCaixaEntrada");
    },
    async getWabas() {
      await ApiService.get("whatsapp/wabas").then((response) => {
        this.wabaOptions = response.data.data;
      });
      if (this.wabaOptions.length > 0) {
        this.loadNumbersInformation();
      }
    },
    async getAccounts() {
      await ApiService.get("administrativo/conta").then((response) => {
        this.accountOptions = [];
        this.accountOptions.push({
          value: response.data.contaPrincipal.conta_id,
          text: response.data.contaPrincipal.con_login,
          name: response.data.contaPrincipal.con_nome_identificacao
        });
        response.data.subContas.forEach((element) => {
          this.accountOptions.push({
            value: element.conta_id,
            text: element.con_login,
            name: element.con_nome_identificacao
          });
        });
      });
    },
    async loadNumbersInformation() {
      this.accounts = [];
      this.wabaOptions.forEach(async (waba) => {
        await ApiService.get(
          "whatsapp/phone_numbers?waba_id=" + waba.waba_id
        ).then((response) => {
          this.numbers = [];
          response.data.data.forEach((element) => {
            let number = {
              name: element.name,
              number_id: element.number_id,
              phone_number: element.phone_number,
              conta_id: element.conta_id,
              registered: element.registered,
              inbox: element.inbox,
              inbox_name: null
            };
            this.numbers.push(number);
          });
        });

        this.accounts.push({
          waba_id: waba.waba_id,
          waba_name: waba.name,
          numbers: this.numbers
        });
      });
    },
    salvarNumerosWhatsapp() {
      this.$bvModal.hide("mdlCaixaEntrada");

      ApiService.post("whatsapp/create_inbox", {
        number: this.selectedNumber,
        waba_id: this.selectedWaba
      }).then(() => {
        Swal.fire({
          title: "SUCESSO",
          text: "Caixa de entrada criada com sucesso!",
          icon: "success",
          heightAuto: false
        }).catch(() => {
          Swal.fire({
            title: "ERRO",
            text: "Erro ao criar caixa de entrada!",
            icon: "error",
            heightAuto: false
          });
        });
      });
    },
    register(numero, waba_id) {
      ApiService.post("whatsapp/register", {
        number: numero,
        waba_id: waba_id
      })
        .then(() => {
          Swal.fire({
            title: "SUCESSO",
            text: "Número registrado com sucesso!",
            icon: "success",
            heightAuto: false
          });
          this.loadNumbersInformation();
        })
        .catch(() => {
          Swal.fire({
            title: "ERRO",
            text: "Erro ao registrar número.",
            icon: "error",
            heightAuto: false
          });
        });
    }
  }
};
</script>

<style>
.status-on {
  background-color: rgba(97, 191, 3, 0.308);
  border-radius: 90%;
  width: 9px;
  height: 20px;
  color: green;
}
.status-off {
  background-color: rgb(214, 21, 21, 0.308);
  border-radius: 90%;
  width: 9px;
  height: 20px;
  color: red;
}

.table-numbers {
  width: 100%;
  border-top: 1.5px solid #e0e0e0;
  border-left: 1.5px solid #e0e0e0;
  border-right: 1.5px solid #e0e0e0;
  border-bottom: 1.5px solid #e0e0e0;
  border-radius: 10px;
  border-collapse: separate;
  table-layout: fixed;
}

.table-numbers td {
  vertical-align: middle;
}
</style>
