<template>
  <div id="app">
    <modal name="modal-editar-permissoes" scrollable width="70%" height="auto">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="nome-arquivo"></h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Descrição do arquivo:</label>
            <textarea
              class="form-control arq_descricao"
              rows="4"
              id="descricao"
              ref="descricaoCampo"
            ></textarea>
          </div>
          <div class="form-group">
            <label
              >Unidades com permissão para visualizar/baixar o arquivo:</label
            >
            <ul id="lista-permissoes" class="list-unstyled grid-list">
              <li>
                <div class="checkbox all">
                  <label>
                    <input
                      type="checkbox"
                      value="-1"
                      ref="checkboxes"
                      id="checkboxTodos"
                      v-model="todasUnidadesCheckbox"
                      @change="mudancaCheckboxUnidadesPermissoes"
                    />
                    Todas as unidades
                  </label>
                </div>
              </li>
              <li v-for="unidade in unidades" :key="unidade.unidade_id">
                <label>
                  <input
                    type="checkbox"
                    :value="unidade.unidade_id"
                    ref="checkboxes"
                    :id="'checkbox-' + unidade.unidade_id"
                    :disabled="checkboxDesabilitado"
                  />
                  {{ unidade.nome_fantasia }}
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary btn-save"
            data-dismiss="modal"
            @click="atualizarArquivo()"
          >
            Salvar
          </button>
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            @click="esconderModalEditarPermissoes"
          >
            Cancelar
          </button>
        </div>
      </div>
    </modal>
    <div
      class="container"
      style="margin-bottom: 20px
      background-color: #cfcbcb"
    >
      <div class="card-title mb-0">
        <h5 class="card-label mb-0" style="float: left">Filtros</h5>
        <div
          class="d-flex justify-content-end align-items-center"
          style="margin-bottom: 0.8rem"
        >
          <a
            class="btn btn-icon btn-light-primary mr-1 card-button-collapse"
            style="float: right"
            @click="minimizarItem"
          >
            <i :class="classeIconeSeta"></i>
          </a>
          <a
            class="btn btn-light-warning font-weight-bold"
            @click.prevent="filtrarArquivos"
          >
            Filtrar
          </a>
        </div>
      </div>
      <div class="clearfix"></div>

      <div v-if="!minimizado">
        <div class="row" style="padding-top: 0.8rem">
          <div class="col-md-4">
            <label style="display: block">Filtrar por Tipo de Arquivo:</label>
            <div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="filter_tipos"
                    value="todos"
                    v-model="todosCheckbox"
                    @change="mudancaTodosCheckbox"
                  />
                  Todos
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="filter_tipos"
                    value="documentos"
                    v-model="documentosCheckbox"
                    @change="mudancaOutrosCheckbox"
                  />
                  Documentos
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="filter_tipos"
                    value="planilhas"
                    v-model="planilhasCheckbox"
                    @change="mudancaOutrosCheckbox"
                  />
                  Planilhas
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="filter_tipos"
                    value="slides"
                    v-model="slidesCheckbox"
                    @change="mudancaOutrosCheckbox"
                  />
                  Slides
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="filter_tipos"
                    value="imagens"
                    v-model="imagensCheckbox"
                    @change="mudancaOutrosCheckbox"
                  />
                  Imagens
                </label>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <label class="form-control-label">Filtrar por mês:</label>
            <vue-monthly-picker
              class="mb-2"
              v-model="dataSelecionada"
              :monthLabels="meses"
              :dateFormat="'MM/YYYY'"
              style="width: 300px"
            ></vue-monthly-picker>
          </div>
          <div class="col-md-4">
            <label class="form-control-label" style="display: block"
              >Filtrar por unidade:</label
            >
            <pv-multiselect
              class="form-control"
              emptyFilterMessage="Não existem unidades cadastradas"
              filter
              optionLabel="und_codigo"
              :options="unidades"
              placeholder="Todas"
              v-model="unidadesSelecionadas"
              optionValue="unidade_id"
              style="width: 300px"
            >
              <template slot="option" slot-scope="{ option }">
                {{ option.und_codigo }} - {{ option.nome_fantasia }}
              </template>
            </pv-multiselect>
          </div>
        </div>
      </div>
    </div>

    <div class="selecionar-arquivo" style="display: flex; margin-bottom: 1rem">
      <b-button variant="primary" @click="abrirSeletorArquivo"
        >Adicionar arquivo</b-button
      >
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        @change="selecionarArquivo"
      />
    </div>

    <div id="main-content">
      <div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th style="width: 380px">Nome</th>
              <th style="width: 200px">Proprietário</th>
              <th style="width: 20px">Unidade(s)</th>
              <th style="width: 40px">Tamanho</th>
              <th style="width: 30px">Data</th>
              <th style="width: 30px">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="arquivo in arquivosPaginados" :key="arquivo.arquivo_id">
              <td>{{ arquivo.arq_nome }}</td>
              <td>{{ arquivo.usr_nome }}</td>
              <td>{{ arquivo.unidades }}</td>
              <td>{{ arquivo.arq_tamanho }} KB</td>
              <td>{{ formatarData(arquivo.arq_data_cadastro) }}</td>
              <td>
                <div class="icones-container">
                  <a
                    @click="baixarArquivo(arquivo)"
                    target="_blank"
                    class="btn-sm btn-primary start icone-container"
                    title="Visualizar"
                  >
                    <i
                      class="fas fa-download text-white"
                      style="font-size: small"
                    ></i>
                  </a>
                  <a
                    class="btn-sm btn-warning cancel icone-container"
                    title="Editar Permissões"
                    @click="exibirModalEditarPermissoes(arquivo)"
                  >
                    <i
                      class="fas fa-pencil text-white"
                      style="font-size: small"
                    ></i>
                  </a>
                  <a
                    class="btn-sm btn-danger delete icone-container"
                    title="Excluir"
                    @click="excluirArquivo(arquivo.arquivo_id)"
                  >
                    <i
                      class="fas fa-trash text-white"
                      style="font-size: small"
                    ></i>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tabela-rodape">
        <div class="legenda">
          {{ legendaExibicao }}
        </div>
        <div class="pagination-container">
          <div class="itens-por-pagina">
            <label for="itensPorPagina">Itens por página:</label>
            <select
              id="itensPorPagina"
              v-model="qtdItensPorPagina"
              @change="atualizarItensPorPagina"
              style="
                -webkit-appearance: auto;
                -moz-appearance: auto;
                appearance: auto;
              "
            >
              <option
                v-for="opcao in opcoesItensPorPagina"
                :value="opcao"
                :key="opcao"
              >
                {{ opcao }}
              </option>
            </select>
          </div>
          <b-pagination
            v-model="paginaAtual"
            :total-rows="totalArquivos"
            :per-page="itensPorPagina"
            align="right"
            size="sm"
            class="mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import "vuetify/dist/vuetify.css";
import Vuetify from "vuetify";
import VueMonthlyPicker from "vue-monthly-picker";

Vue.use(Vuetify);

export default {
  components: {
    VueMonthlyPicker
  },
  data() {
    return {
      filtros: {
        tipo: ["todos"], // Valor padrão para exibir todos os tipos
        mes: "",
        unidade: ""
      },
      arquivo: null,
      arquivos: [],
      unidades: [],
      urlBase: "",
      role: "",
      token: "",
      dataSelecionada: null,
      unidadesSelecionadas: [],
      arquivoSelecionado: null,
      minimizado: true,
      paginaAtual: 1,
      qtdItensPorPagina: 15,
      opcoesItensPorPagina: [15, 25, 50],
      todosCheckbox: true,
      documentosCheckbox: false,
      planilhasCheckbox: false,
      slidesCheckbox: false,
      imagensCheckbox: false,
      todasUnidadesCheckbox: false,
      checkboxDesabilitado: false,
      meses: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez"
      ]
    };
  },
  mounted() {
    this.inicializar();
  },
  computed: {
    classeIconeSeta() {
      return this.minimizado
        ? "ki ki-arrow-down icon-nm"
        : "ki ki-arrow-up icon-nm";
    },
    totalArquivos() {
      return this.arquivos.length;
    },
    itensPorPagina() {
      return this.qtdItensPorPagina;
    },
    arquivosPaginados() {
      const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
      const fim = inicio + this.itensPorPagina;
      return this.arquivos.slice(inicio, fim);
    },
    legendaExibicao() {
      const inicio = (this.paginaAtual - 1) * this.itensPorPagina + 1;
      const fim = Math.min(
        this.paginaAtual * this.itensPorPagina,
        this.totalArquivos
      );
      return `Exibindo ${inicio} - ${fim} de ${this.totalArquivos} arquivo${
        this.totalArquivos !== 1 ? "s" : ""
      }`;
    }
  },
  methods: {
    async inicializar() {
      await this.autenticarBI();
      this.receberUnidades();
      this.listarArquivos();
    },
    async autenticarBI() {
      await ApiService.get("nps").then((response) => {
        this.urlBase = response.data.data.url;
        this.role = response.data.data.role;
        this.token = response.data.data.token;
      });
    },
    async receberUnidades() {
      let resposta = await axios.get(`${this.urlBase}unidades`, {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      });
      this.unidades = resposta.data;
    },
    async listarArquivos(und = "", tipos = "", mes = "", _ = "") {
      let resposta = await axios.get(
        `${this.urlBase}arquivos?unidades=${und}&tipos=${tipos}&mes=${mes}&_=${_}`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        }
      );
      this.arquivos = resposta.data.data;
    },
    abrirSeletorArquivo() {
      this.$refs.fileInput.click();
    },
    selecionarArquivo(event) {
      const file = event.target.files[0];
      this.carregarArquivo(file);
    },
    carregarArquivo(arquivo) {
      const formData = new FormData();
      formData.append("file", arquivo);

      axios
        .post(`${this.urlBase}arquivo/upload`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then((response) => {
          alert("Arquivo adicionado com sucesso!");
          this.listarArquivos();
        })
        .catch((error) => {
          console.error("Erro ao enviar o arquivo:", error);
          alert("Erro ao enviar o arquivo");
        });
    },
    exibirModalEditarPermissoes(arquivo) {
      this.arquivo = arquivo;
      this.$modal.show("modal-editar-permissoes");

      this.receberPermissoes(arquivo);
    },
    esconderModalEditarPermissoes() {
      this.$modal.hide("modal-editar-permissoes");
    },
    async receberPermissoes(arquivo) {
      let resposta = await axios.get(
        `${this.urlBase}arquivo/${arquivo.arquivo_id}`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        }
      );
      let permissoes = resposta.data.data.unidades;

      document.getElementById("nome-arquivo").textContent = arquivo.arq_nome;
      document.getElementById("descricao").textContent = arquivo.arq_descricao;

      let checkboxTodos = document.getElementById("checkboxTodos");

      // Cria os elementos <li> para cada unidade
      this.unidades.forEach(function (unidade) {
        let checkbox = document.getElementById(
          "checkbox-" + unidade.unidade_id
        );
        if (permissoes.includes(unidade.unidade_id)) {
          checkbox.checked = true;
        }
      });

      if (permissoes.length == 0) {
        checkboxTodos.checked = true;
        this.todasUnidadesCheckbox = true;
      } else {
        this.todasUnidadesCheckbox = false;
      }

      this.mudancaCheckboxUnidadesPermissoes();
    },
    mudancaTodosCheckbox() {
      if (this.todosCheckbox) {
        // Se a opção "todos" foi marcada, desmarca as outras opções
        this.documentosCheckbox = false;
        this.planilhasCheckbox = false;
        this.slidesCheckbox = false;
        this.imagensCheckbox = false;
      }
    },
    mudancaOutrosCheckbox() {
      // Se uma das outras opções foi marcada, desmarca a opção "todos"
      this.todosCheckbox = false;
    },
    mudancaCheckboxUnidadesPermissoes() {
      if (this.todasUnidadesCheckbox) {
        this.checkboxDesabilitado = true;
      } else {
        this.checkboxDesabilitado = false;
      }
    },
    async filtrarArquivos() {
      const tipoArquivoSelecionado = [];
      if (this.todosCheckbox) {
        tipoArquivoSelecionado.push("todos");
      }
      if (this.documentosCheckbox) {
        tipoArquivoSelecionado.push("documentos");
      }
      if (this.planilhasCheckbox) {
        tipoArquivoSelecionado.push("planilhas");
      }
      if (this.slidesCheckbox) {
        tipoArquivoSelecionado.push("slides");
      }
      if (this.imagensCheckbox) {
        tipoArquivoSelecionado.push("imagens");
      }

      const mesSelecionado =
        this.dataSelecionada != null
          ? this.dataSelecionada.format("YYYY-MM")
          : "";

      const unidadesSelecionadas = this.unidadesSelecionadas.map(
        (unidade) => unidade
      );

      this.listarArquivos(
        unidadesSelecionadas,
        tipoArquivoSelecionado,
        mesSelecionado
      );
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    atualizarItensPorPagina() {
      this.paginaAtual = 1; // Reiniciar a página atual ao alterar a quantidade de itens por página
    },
    minimizarItem() {
      this.minimizado = !this.minimizado;
    },
    excluirArquivo(arquivo_id) {
      if (window.confirm("Deseja realmente excluir este arquivo?")) {
        // O usuário clicou em "OK"
        axios
          .post(`${this.urlBase}arquivo/remover/${arquivo_id}`, null, {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          })
          .then((response) => {
            alert("Arquivo excluído com sucesso!");
            this.listarArquivos();
          })
          .catch((error) => {
            alert("Erro ao tentar excluir o arquivo");
          });
      }
    },
    async baixarArquivo(arq) {
      let arq_identificador = arq.arq_identificador;
      let arq_nome = arq.arq_nome;

      let urlDownload =
        "https://bi.comunicante.net.br/" +
        this.urlBase.split("/")[3] +
        "/arquivo/download/" +
        arq_identificador;

      let resposta = await axios
        .get(urlDownload, {
          headers: {
            Authorization: `Bearer ${this.token}`
          },
          responseType: "blob"
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = arq_nome;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async atualizarArquivo() {
      const descricao = this.$refs.descricaoCampo.value;
      const checkboxes = document.querySelectorAll(
        '#lista-permissoes input[type="checkbox"]:checked'
      );
      const valoresMarcados = [];

      checkboxes.forEach((checkbox) => {
        valoresMarcados.push(checkbox.value);
      });

      const formData = new FormData();

      // Adicione os parâmetros ao objeto FormData
      formData.append("arq_descricao", descricao);

      if (valoresMarcados.length != 0 && valoresMarcados[0] != -1) {
        valoresMarcados.forEach((valor) => {
          formData.append("unidades[]", valor);
        });
      }

      axios
        .post(`${this.urlBase}arquivo/${this.arquivo.arquivo_id}`, formData, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then((response) => {
          alert("Arquivo atualizado com sucesso!");
          this.listarArquivos();
          this.esconderModalEditarPermissoes();
        })
        .catch((error) => {
          alert("Erro ao tentar atualizar o arquivo");
        });
    }
  }
};
</script>

<style scoped>
.grid-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  max-height: 200px;
  overflow-y: auto;
}
.selecionar-arquivo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}
.upload-button {
  margin-bottom: 5px;
}
.icone-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icones-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}
.btn-sm {
  padding: 0.3rem 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
}

.btn-primary:hover,
.btn-warning:hover,
.btn-danger:hover {
  opacity: 0.9;
}
.tabela-rodape {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.pagination-container {
  display: flex;
  align-items: center;
}
.itens-por-pagina {
  text-align: center;
  margin-right: 10px; /* Adicione margem à direita para separar dos botões de paginação */
}
#app {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.card-filtro {
  display: flex;
}

.input-group-addon {
  cursor: pointer;
}
.tabela-arquivos {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  /* Adicione a margem superior desejada */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}
</style>
